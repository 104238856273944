import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(0.5),
        },
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

export default function Search() {
    const classes = useStyles();

    const handleClick = () => {
        console.info('You clicked the Chip.');
    };

    return (
        <div className={classes.root}>
            <TextField
                id="outlined-full-width"
                style={{ margin: 8 }}
                placeholder="Placeholder"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
            />
            <Zoom in={true} timeout={100}>
                <Chip label="Clickable" onClick={handleClick} />
            </Zoom>
            <Zoom in={true} timeout={200}>
                <Chip label="Clickable" onClick={handleClick} />
            </Zoom>
            <Zoom in={true} timeout={300}>
                <Chip label="Clickable" onClick={handleClick} />
            </Zoom>
            <Zoom in={true} timeout={400}>
                <Chip label="Clickable" onClick={handleClick} />
            </Zoom>
        </div>
    );
}